import React from 'react'
import { graphql, Link } from 'gatsby'
import LayoutRoot from '../components/LayoutRoot'
import styles from '../styles/tag.module.css'
import classnames from 'classnames'
import Img, { FixedObject, FluidObject } from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

/**
 *   This is the return format for a single recipe query
 *   Try here http://localhost:8000/___graphql
 
 query MyQuery {
  strapiOtherPage(website_type: {name: {eq: "food_recipe"}}) {
    id
    name
    mainContent
    website_type {
      name
    }
  }
}

Returns 
{
  "data": {
    "strapiOtherPage": {
      "id": "Other-page_1",
      "name": "about",
      "mainContent": "blabla"
      "website_type": {
        "name": "food_recipe"
      }
    }
  },
  "extensions": {}
}
 */

export interface OtherPageProps {
    data: {
        strapiOtherPage: {
            id: number
            strapiId: string
            slug: string
            name: string
            mainContent: string
            website_type: {
                name: string
            }
        }
    }
}

export const query = graphql`
  query OtherPage($id: Int!) {
    strapiOtherPage(strapiId: { eq: $id } website_type: {name: {eq: "food_recipe"}}) {
        id
        strapiId
        slug
        name
        mainContent
        website_type {
          name
        }
    }
  }
`

const OtherPage: React.FC<OtherPageProps> = ({ data }) => {
    const mainContent = data.strapiOtherPage.mainContent
 

    return (
      <LayoutRoot>
        <div className={classnames(styles.main)}>
           <ReactMarkdown source={mainContent} />
        </div>
      </LayoutRoot>
  )
}

export default OtherPage